import React, { useEffect } from "react";
import { useStatusContext } from "../../Context/StatusContextProvider";

const SubmitResponse = () => {
  const { grievanceRegistrationData } = useStatusContext();

  useEffect(() => {
    console.log(grievanceRegistrationData);
  }, []);

  return (
    <div className=" bg-white  flex flex-col justify-start items-center p-8 m-2">
      <div className="logoTick">
        <img src="./assets/approve.svg" alt="" className="h-36" />
      </div>
      <div className="font-bold text-5xl text-sky-500">{`# ${
        grievanceRegistrationData.data.grievanceID === undefined
          ? "0"
          : grievanceRegistrationData.data.grievanceID
      }`}</div>
      <div className="font-bold text-lg text-[#757575] my-8 text-center md:w-2/4">
        We've recieved your grievance and we will start working on it shortly.
        Here's your token number, please note it down and keep it saved as it
        will be used to check status of your grievance in future.
      </div>
      {/* <div className="w-full bg-[#a19f9f] flex justify-center p-8 font-bold">
        Token No. :
      </div> */}
      <div></div>
    </div>
  );
};

export default SubmitResponse;
