import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { MdOutlineVisibility, MdDone } from "react-icons/md";
import { BiReset } from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useStatusContext } from "../../Context/StatusContextProvider";
import swal from "sweetalert";

const Status = () => {
  let navigate = useNavigate();

  const { setViewStatusPageData } = useStatusContext();

  const [tokenNo, setTokenNo] = useState("");
  const [mobileNumber, setMobilenumber] = useState("");
  const [captchaValue, setCaptchaValue] = useState();
  let sendingData = {
    tokenNo,
    mobileNumber,
  };

  const viewGrievanceStatusFunc = (e) => {
    console.log(sendingData)
    e.preventDefault();
    if (captchaValue != null) {
      if (tokenNo.length !== 0 && mobileNumber) {
        navigate("/status-response");
        setViewStatusPageData(sendingData);
      } else {
        if (tokenNo.length === 0 && !mobileNumber) {
          swal(
            "Form is not filled",
            "Please fill Token number and DoB to see the status",
            "warning"
          );
        } else if (tokenNo.length === 0) {
          swal(
            "Token Number is not mentioned",
            "Please fill Token number to see the status",
            "warning"
          );
        } else if (!mobileNumber) {
          swal(
            "Mobile Number is not mentioned",
            "Please mention DOB to see the status",
            "warning"
          );
        }
      }
    } else {
      swal("Please Validate Captcha", "Please Verify Your Not Robot", "error");
    }
  };

  return (
    <div className="w-full">
      <div className="bg-[#ffffff] mx-1 sm:mx-28 my-5 shadow-xl rounded-md">
        <div className="topStatus flex items-center justify-between bg-[#8a33f5] p-2">
          <span className="flex items-center gap-1">
            <span>
              <MdOutlineVisibility className="text-[#ffffff] text-xl" />
            </span>
            <span className="text-[#ffffff] font-bold text-lg">
              View Status Form
            </span>
          </span>
        </div>
        <div className="statusLoginBar w-full">
          <form
            method="post"
            className="flex flex-col justify-center gap-6 w-fit mx-auto py-6"
          >
            <div className=" my-4 flex flex-col sm:flex-row items-baseline">
              <label className="font-semibold w-56 text-[#000000]">
                Token Number / टोकन नंबर  :
              </label>
              <TextField
                required
                id="standard-required"
                placeholder="Token No."
                variant="standard"
                className="w-full"
                onChange={(e) => {
                  setTokenNo(e.target.value);
                }}
              />
            </div>
            <div className=" mb-4 flex flex-col sm:flex-row items-baseline">
              <label className="font-semibold w-56 text-[#000000]">Mobile Number / मोबाइल नंबर :</label>
              <TextField
              required
              id="standard-required"
              placeholder="Phone Number"
              type="tel"
              variant="standard"
              className="w-full"
              value={mobileNumber}
              // disabled={grievanceUploadLoading}
              onChange={(e) => setMobilenumber(e.target.value)}
            />
            </div>
            <div className="mt-6 flex justify-center">
              <ReCAPTCHA
                sitekey="6Le0fJYhAAAAAPO1XZ7AkUzWvPTdc5CbegVKorPB"
                onChange={(e) => setCaptchaValue(e)}
              />
            </div>
            <div className="w-full flex justify-center gap-4">
              <button
                type="submit"
                e
                className="bg-[#6565f0] flex items-center pr-5 pl-3 py-2 font-bold text-[#ffffff] rounded-md text-lg"
                onClick={viewGrievanceStatusFunc}
              >
                <MdDone className="mr-1" />
                Submit
              </button>
              <button
                type="reset"
                className="bg-[#6565f0] flex items-center pr-5 pl-3 py-2 font-bold text-[#ffffff] rounded-md text-lg"
                onClick={() => {
                  setTokenNo("");
                  setMobilenumber("");
                }}
              >
                <BiReset className="text-[#ffffff] mr-1" />
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Status;
