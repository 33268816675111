import React, { createContext, useContext, useState } from "react";

const StatusContext = createContext();

export const StatusContextProvider = ({ children }) => {
  const [viewStatusPageData, setViewStatusPageData] = useState({});
  const [stationDetailsContext, setStationDetailsContext] = useState([]);
  const [singleGrievanceActionList, setSingleGrievanceActionList] = useState(
    []
  );
  const [grievanceRegistrationData, setGrievanceRegistrationData] = useState([])
  return (
    <StatusContext.Provider
      value={{
        viewStatusPageData,
        setViewStatusPageData,
        stationDetailsContext,
        setStationDetailsContext,
        singleGrievanceActionList,
        setSingleGrievanceActionList,
        grievanceRegistrationData, 
        setGrievanceRegistrationData
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const useStatusContext = () => useContext(StatusContext);
