import React, { useEffect, useState } from "react";
import { MdOutlineVisibility } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useStatusContext } from "../../Context/StatusContextProvider";
import RightTimeline from "../RightTimeline";

const StatusResponse = () => {
  const url = "https://faep.airchains.technology/api/";
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();
  // const url = "http://localhost:6969/";

  let resetData = {
    tokenNo: "",
    mobileNumber: "",
  };

  const {
    viewStatusPageData,
    setSingleGrievanceActionList,
    singleGrievanceActionList,
  } = useStatusContext();
  const [grievanceDetails, setGrievanceDetails] = useState("");

  const DataFormatter = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const apiDate = new Date(date);
    const MyDate = `${apiDate.getDate()} ${
      monthNames[apiDate.getMonth()]
    }, ${apiDate.getFullYear()}`;
    return MyDate;
  };

  const callAPIforDetails = async () => {
    try {
      setLoading(true);
      const resGrievance = await fetch(url + "grievance/viewGrievance", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          grievanceToken: viewStatusPageData.tokenNo,
          mobileNumber: viewStatusPageData.mobileNumber,
        }),
      });
      const GrievanceData = await resGrievance.json();
      setLoading(false);
      setGrievanceDetails(GrievanceData.data);
      console.log(GrievanceData.data.actions);
      setSingleGrievanceActionList(GrievanceData.data.actions);
      console.log(grievanceDetails);
      if (GrievanceData.status === 500) {
        setLoading(true);
        swal("Wrong Information Entered", GrievanceData.description, "error");
        setTimeout(() => {
          navigate("/viewstatus");
        }, 2000);
      }
      // setViewStatusPageData(resetData);
    } catch (err) {
      console.log(err);
      swal("Wrong Information Entered", err, "error");
      setTimeout(() => {
        navigate("/viewstatus");
      }, 2000);
    }
  };

  useEffect(() => {
    if (
      viewStatusPageData.tokenNo === undefined ||
      viewStatusPageData.tokenNo.length === 0
    ) {
      navigate("/not-found");
    } else {
      callAPIforDetails();
    }
  }, []);

  return (
    <div className="bg-[#ffffff] mx-1 my-4 shadow-md rounded-md sm:mx-28 w-full p-8">
      {loading ? (
        <div className="flex items-center justify-center text-3xl font-semibold">
          Loading ...
        </div>
      ) : (
        <div className="mx-auto flex flex-col justify-center items-center gap-8">
          <div className="row w-full mx-auto flex flex-row items-center justify-start gap-4">
            <div className="topStatus w-full flex items-center justify-between bg-[#8a33f5] p-2">
              <span className="flex items-center gap-1">
                <span>
                  <MdOutlineVisibility className="text-[#ffffff] text-xl" />
                </span>
                <span className="text-[#ffffff] font-bold text-lg">
                  Grievance Details / शिकायत विवरण
                </span>
              </span>
            </div>
          </div>
          <div className="row w-full mx-auto flex flex-col items-start justify-center gap-4">
            <div className="w-full flex items-start justify-start  gap-4">
              <div className="w-5/12">
                {" "}
                {grievanceDetails.grievance_image === "none" ? (
                  "Image is not available"
                ) : (
                  <img
                    className="md:w-full"
                    src={`https://faep.airchains.technology/api/uploads/${grievanceDetails.grievance_image}`}
                    alt="Grievance Image"
                  />
                )}
              </div>
              <div className="details flex-1 flex flex-col items-start justify-start gap-2 ">
                <p className=" w-full text-4xl mb-2">
                  <span className="font-bold text-sky-500">
                    #{grievanceDetails.grievanceID}
                  </span>
                </p>
                <p className=" w-full ">
                  <span className="font-bold">Mobile / मोबाइल :</span>{" "}
                  <span>{grievanceDetails.grievanceMobile}</span>
                </p>
                <p>
                  <span className="font-bold">Name / नाम :</span>{" "}
                  <span>{grievanceDetails.grievanceName}</span>
                </p>
                <p>
                  <span className="font-bold">Address / पता : </span>
                  <span> {grievanceDetails.grievanceAddress}</span>
                </p>
                <p>
                  <span className="font-bold">Village / गाँव : </span>{" "}
                  <span>{grievanceDetails.grievanceVillage}</span>
                </p>
                <p>
                  <span className="font-bold">Station / थाना: </span>{" "}
                  <span>{grievanceDetails.stationName}</span>
                </p>
              </div>
            </div>

            <div class="overflow-x-auto relative w-full shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                {/* <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      Product name
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Color
                    </th>
                  </tr>
                </thead> */}
                <tbody>
                  <tr class="bg-white w-full border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-semibold w-4/12 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Grievance Title / शिकायत शीर्षक :
                    </th>
                    <td class="py-4 px-6">{grievanceDetails.grievanceTitle}</td>
                  </tr>
                  <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-semibold w-4/12 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Grievance Description / शिकायत:
                    </th>
                    <td class="py-4 px-6">
                      {grievanceDetails.grievanceDescription}
                    </td>
                  </tr>
                  <tr class="bg-white w-full border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-semibold w-4/12 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Officer Alloted / आवंटित अधिकारी :
                    </th>
                    <td class="py-4 px-6">
                      {grievanceDetails.officer_id === "--"
                        ? "No Officer Alloted"
                        : grievanceDetails.officerAlloted}
                    </td>
                  </tr>
                  <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-semibold w-4/12 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Date of Birth / जन्म तारीख:
                    </th>
                    <td class="py-4 px-6">
                      {DataFormatter(grievanceDetails.grievanceDOB)}
                    </td>
                  </tr>
                  <tr class="bg-white w-full border-b dark:bg-gray-900 dark:border-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-semibold w-4/12 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Status / स्थिति :
                    </th>
                    <td class="py-4 px-6">
                      {getFlagStatus(grievanceDetails.g_flag)}
                    </td>
                  </tr>
                  <tr class="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      class="py-4 px-6 font-semibold w-4/12 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Grievance Creation Date / शिकायत पंजीयन तारीख:
                    </th>
                    <td class="py-4 px-6">
                      {DataFormatter(grievanceDetails.create_date)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="actions-main-field bg-sky-300 font-semibold w-full px-2 py-2 rounded-md shadow-md">
              Actions / कार्रवाई
            </div>
            <div className="timeline">
              {singleGrievanceActionList === undefined ? (
                "No actions"
              ) : (
                <RightTimeline />
              )}
            </div>

            {/* <p className="px-6">
              <span className="font-bold">Grievance Title : </span>
              <span>{grievanceDetails.grievanceTitle}</span>
            </p>
            <p className="px-6">
              <span className="font-bold">Grievance Description : </span>
              <span>{grievanceDetails.grievanceDescription}</span>
            </p>
            <p className="px-6">
              <span className="font-bold">Officer Alloted : </span>{" "}
              <span>
                {grievanceDetails.officer_id === "--"
                  ? "No Officer Alloted"
                  : grievanceDetails.officerAlloted}
              </span>
            </p>

            <section className=" w-full flex flex-col gap-4 md:flex-row">
              <p className="px-6 md:w-1/2">
                <span className="font-bold">Date of Birth : </span>{" "}
                <span>{DataFormatter(grievanceDetails.grievanceDOB)}</span>
              </p>
            </section>
            <section className=" w-full flex flex-col gap-4 lg:flex-row">
              <p className="px-6 lg:w-1/2">
                <span className="font-bold">Status : </span>{" "}
                <span>{getFlagStatus(grievanceDetails.g_flag)}</span>
              </p>
              <p className="px-6 lg:w-1/2">
                <span className="font-bold">Grievance Creation Date : </span>
                <span>{DataFormatter(grievanceDetails.create_date)}</span>
              </p>
            </section> */}
          </div>
          <div className="row w-full mx-auto flex flex-row items-center justify-between"></div>
        </div>
      )}
    </div>
  );
};

export default StatusResponse;

const getFlagStatus = (flag) => {
  switch (flag) {
    case "0":
      return "Unalloted";
    case "1":
      return "Officer Alloted";
    case "2":
      return "Success";
    case "3":
      return "Rejected";
    default:
      return "Unknown";
  }
};
