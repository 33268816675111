import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material";
import { useStatusContext } from "../Context/StatusContextProvider";

const theme = createTheme({
  overrides: {
    MuiTimelineItem: {
      missingOppositeContent: {
        display: "none",
        "&:before": {
          display: "none",
        },
      },
    },
  },
});

const RightTimeline = () => {
  const { singleGrievanceActionList } = useStatusContext();

  const getMyCustomDate = (date) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const apiDate = new Date(date);
    const MyDate = `${apiDate.getDate()} ${
      monthNames[apiDate.getMonth()]
    }, ${apiDate.getFullYear()}`;
    return MyDate;
  };

  return (
    <ThemeProvider theme={theme}>
      {singleGrievanceActionList.map((item) => (
        <Timeline position="right" key={item._id}>
          <TimelineItem>
            <TimelineOppositeContent
              style={{
                maxWidth: "1px",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
            />
            <TimelineSeparator>
              <TimelineDot sx={{ backgroundColor: "#1e8bf1" }}></TimelineDot>
              <TimelineConnector sx={{ backgroundColor: "#1e8bf1" }} />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "6px", px: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                }}
                component="span"
              >
                {item.grievance_action_title}
                {/* Eat */}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  width: "200px",
                }}
              >
                {getMyCustomDate(item.create_date)}
                {/* Because you need strength */}
              </Typography>
              <Typography variant="caption">
                {item.grievance_action_remarks}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      ))}
    </ThemeProvider>
  );
};

export default RightTimeline;
