import React from "react";
import Home from "./Components/pages/Home";
import RegisterGrievance from "./Components/pages/RegisterGrievance";
import Status from "./Components/pages/Status";
import StatusResponse from "./Components/pages/StatusResponse";
import SubmitResponse from "./Components/pages/SubmitResponse";
import FourZeroFour from "./Components/pages/FourZeroFour";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";


const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path="/register-grievancer" element={<RegisterGrievance />} />
          <Route path="/viewstatus" element={<Status />} />
          <Route path="/status-response" element={<StatusResponse />} />
          <Route path="/submit-response" element={<SubmitResponse />} />
        </Route>
        <Route path="*" element={<FourZeroFour />} />
      </Routes>
    </div>
  );
};

export default App;
