import React from "react";
import { BsFillEnvelopeFill } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="py-2 px-5 bg-[#4c4cd9] sm:flex sm:justify-between sm:items-center sm:px-28">
      <span className="font-medium text-[#ffffff]">
        Copyright © {new Date().getFullYear()} <a href="https://airchains.io/" target="_blank">Airchains</a>
      </span>
      <span className="flex items-center gap-2 mt-2">
        <span className="text-[#b4f57f]">
          {" "}
          <BsFillEnvelopeFill />{" "}
        </span>
        <span className="font-medium text-[#ffffff]">
          <a href="mailto:spfrz-up@nic.in">spfrz-up@nic.in</a>
        </span>
      </span>
    </div>
  );
};

export default Footer;
