import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";

const Dashboard = () => {
  useEffect(() => {
    let upperSection = document.getElementById("upperSection");
    let outletSection = document.getElementById("outletSection");
    let mainFooter = document.getElementById("mainFooter");

    outletSection.style.minHeight = `calc(100vh - ${
      upperSection.offsetHeight + mainFooter.offsetHeight
    }px )`;
  }, []);

  return (
    <div className="h-screen relative">
      <div className="upperSection" id="upperSection">
        <Header />
      </div>
      <main className="p-0 flex justify-center items-center" id="outletSection">
        <Outlet />
      </main>
      <footer id="mainFooter">
        <Footer />
      </footer>
    </div>
  );
};

export default Dashboard;
