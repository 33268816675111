import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [navbarDropdown, setNavbarDropdown] = useState(false);

  return (
    <>
      <div className="maindiv">
        <div className="headerSection">
          <div className="p-2 flex items-center sm:justify-between sm:mx-28">
            <div className="left flex items-center justify-start gap-3">
              <img
                src="/assets/upplogo.png"
                alt=""
                className=" w-16 md:w-20 "
              />
              <p className="font-semibold flex flex-col md:text-2xl">
                <span>Firozabad Public Grievance Management System</span>
                <span className="text-lg">फ़िरोज़ाबाद जन शिकायत प्रणाली</span>
              </p>
            </div>
            <div>
              <div className="right">
                <button
                  data-collapse-toggle="navbar-default"
                  type="button"
                  className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white-200 dark:text-white-400 dark:hover:bg-white-700 dark:focus:ring-gray-600"
                  aria-controls="navbar-default"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    onClick={() => {
                      setNavbarDropdown(!navbarDropdown);
                    }}
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={` ${
          navbarDropdown ? "block" : "hidden"
        } w-full md:block md:w-auto `}
      >
        <div className="bg-[#4c4cd9] mt-2 sm:px-28 py-2">
          <ul className=" gap-2 flex flex-col md:flex-row items-start md:items-center justify-between md:justify-start p-2">
            <Link
              to="/"
              onClick={() => {
                setNavbarDropdown(false);
              }}
            >
              <li className="text-base font-normal text-[#ffffff] sm:font-medium sm:text-lg sm:ml-2">
                Home
              </li>
            </Link>
            <Link
              to="/register-grievancer"
              onClick={() => {
                setNavbarDropdown(false);
              }}
            >
              <li className="text-base font-normal text-[#ffffff] sm:font-medium sm:text-lg sm:ml-2">
                Register Grievance
              </li>
            </Link>
            <Link
              to="/viewstatus"
              onClick={() => {
                setNavbarDropdown(false);
              }}
            >
              <li className="text-base font-normal text-[#ffffff] sm:font-medium sm:text-lg sm:ml-2">
                View Status
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
