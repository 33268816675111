import React from "react";
import { BsInfoCircle } from "react-icons/bs";

const Home = () => {
  return (
    <div className="bg-[#ffffff] mx-1 shadow-md rounded-md sm:mx-28 ">
      <div className="alertSection flex items-center bg-[#fcfc77] p-2">
        <span className="mr-1">
          <BsInfoCircle />
        </span>
        <span className="font-semibold text-base">
          About FPGMS / प्रणाली विवरण
        </span>
      </div>
      <div className="my-3 p-2 sm:my-2">
        <p className="text-sm font-medium">
          FPGMS is a customized portal of Firozabad Police(Uttar Pradesh Police)
          with an aim to resolve grievance complaints. Grievances can be lodged
          at any place and will land in concerned stations to which the
          grievances prevail. <br/> प्रणाली शिकायत शिकायतों को हल करने के उद्देश्य
          से फिरोजाबाद पुलिस (उत्तर प्रदेश पुलिस) का एक अनुकूलित पोर्टल है।
          शिकायतें किसी भी स्थान पर दर्ज की जा सकती हैं और संबंधित स्टेशनों पर
          पहुंचेंगी जहां शिकायतें प्रबल होती हैं।
        </p>
        <div className="listContainer my-3">
          <ul className="list-disc mx-4">
            <li className="text-sm font-normal">
              {" "}
              Grievance can be lodged by any individual. / शिकायत कोई भी व्यक्ति
              दर्ज करा सकता है
            </li>
            <li className="text-sm font-normal">
              ReCaptcha verification./ रीकैप्चा सत्यापन
            </li>
            <li className="text-sm font-normal">
              {" "}
              Online lodging of grievance. / शिकायत का ऑनलाइन दर्ज करना
            </li>
            <li className="text-sm font-normal">
              {" "}
              Dedicated officer validation of the grievance. / समर्पित अधिकारी
              शिकायत का सत्यापन
            </li>
            <ul className="list-disc">
              <li className="text-sm font-normal">
                Facility to send reminder for pending grievance. / लंबित
                शिकायतों के लिए रिमाइंडर भेजने की सुविधा
              </li>
              <li className="text-sm font-normal">
                Token number generation for further status inquiry. / आगे की
                स्थिति की पूछताछ के लिए टोकन नंबर जनरेशन
              </li>
              <li className="text-sm font-normal">
                View current status of grievance / शिकायत की वर्तमान स्थिति
                देखें
              </li>
              <li className="text-sm font-normal">
                {" "}
                Village based categorization of grievance. / शिकायत का ग्राम
                आधारित वर्गीकरण
              </li>
              <li className="text-sm font-normal">
                Facility to upload one grievance document. / एक शिकायत दस्तावेज
                अपलोड करने की सुविधा
              </li>
            </ul>
          </ul>
        </div>
        <p className="text-sm font-bold mt-3">
          Further, once a grievance is registered, system generates a unique
          token number and auto generates acknowledgement through SMS & email.
          There is ease of registration of grievance and faster settlement after
          revamp.<br/> इसके अलावा, एक बार शिकायत दर्ज होने के बाद, सिस्टम एक
          अद्वितीय टोकन नंबर उत्पन्न करता है और एसएमएस और ईमेल के माध्यम से ऑटो
          पावती उत्पन्न करता है। शिकायत के पंजीकरण में आसानी होती है और सुधार के
          बाद तेजी से निपटारा होता है।
        </p>
        <p className="text-sm font-bold mt-3 bg-[#bdbfff] text-[#2027f5]  p-3 ">
          "Firozabad Police is working hard to improve the service delivery.
          Kindly give your valuable feedback with respect to registering your
          grievance on the FPGMS Portal and your feedback regarding your
          satisfaction with respect to resolution of your grievance".<br/><br/>"फिरोजाबाद पुलिस सेवा वितरण में सुधार के लिए कड़ी मेहनत कर रही है।
कृपया एफपीजीएमएस पोर्टल पर अपनी शिकायत दर्ज करने के संबंध में अपनी बहुमूल्य प्रतिक्रिया दें और अपनी शिकायत के समाधान के संबंध में अपनी संतुष्टि के संबंध में अपनी प्रतिक्रिया दें।"
        </p>
      </div>
    </div>
  );
};

export default Home;
