import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { ImStack } from "react-icons/im";
import { BsInfoCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import {
  createTheme,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import FormData from "form-data";
import swal from "sweetalert";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { FaHourglassEnd } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useStatusContext } from "../../Context/StatusContextProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#6565f0",
    },
  },
});

const RegisterGrievance = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [mobileNumber, setMobilenumber] = useState("");
  const [dob, setDob] = useState("");
  const [village, setVillage] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [pincode, setPincode] = useState("");
  const [gTitle, setGtitle] = useState("");
  const [gDescription, setgDescription] = useState("");
  const [gImage, setGimage] = useState("");
  const [gPdf, setGPdf] = useState("");
  const [stationid, setstationid] = useState("");
  const [stationDetails, setStationDetails] = useState("");
  const [grievanceUploadLoading, setGrievanceUploadLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState();
  const { setGrievanceRegistrationData } = useStatusContext();
  const url = "https://faep.airchains.technology/api/";

  const getStation = async () => {
    try {
      const resStation = await fetch(url + "grievance/get/stationDetails", {
        method: "GET",
        headers: {
          "Content-type": "application/json",
        },
      });
      const Stationdata = await resStation.json();
      setStationDetails(Stationdata.data);
    } catch (err) {
      console.error(err);
    }
  };

  const uploadGrievance = async () => {
    try {
      setGrievanceUploadLoading(true);
      if (captchaValue != null) {
        if (gPdf === null || gPdf === "") {
          if (gImage === null || gImage === "") {
            setGrievanceUploadLoading(false);
            swal("Please upload File", "Please upload either Image or PDF", "error");

            return false;
          }
        }

        const formdataPDF = new FormData();
        let fileData;
        let fileURL = "none";
        if (gPdf !== null && gPdf !== "") {
          formdataPDF.append("pdffile", gPdf);
          const resFile = await fetch(url + "grievance/uploadPDF", {
            method: "POST",
            headers: { ipaddress: "ip_address" },
            body: formdataPDF,
          });
          fileData = await resFile.json();
          if (fileData.status == 200) {
            fileURL = fileData.description;
          }
        }
        let imagedata;
        let imageURL = "none";
        const formdataImage = new FormData();
        if (gImage !== null && gImage !== "") {
          formdataImage.append("avatar", gImage);

          const resImage = await fetch(url + "grievance/uploadImage", {
            method: "POST",
            headers: { ipaddress: "ip_address" },
            body: formdataImage,
          });

          imagedata = await resImage.json();
          if (imagedata.status == 200) {
            imageURL = imagedata.description;
          }
        }

        // const imagedata = await resImage.json();
        // pdf file api
        // const fileData = await resFile.json();

        const body = JSON.stringify({
          name: name,
          fatherName: fatherName,
          mobileNumber: mobileNumber,
          dob: dob,
          village: village,
          address: address1 + address2 + pincode,
          gTitle: gTitle,
          gDescription: gDescription,
          stationID: stationid,
          gImage: imageURL,
          gPDF: fileURL,
        });
        const resData = await fetch(url + "grievance/uploadGrievance", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ipaddress: "ip_address",
          },
          body: body,
        });
        const grievdata = await resData.json();
        if (grievdata.status === 200) {
          setGrievanceRegistrationData(grievdata);
          navigate("/submit-response");
          setGrievanceUploadLoading(false);
        } else {
          swal(grievdata.message, grievdata.description, "error");
          setGrievanceUploadLoading(false);
        }
      } else {
        setGrievanceUploadLoading(false);
        swal(
          "Please Validate Captcha",
          "Please Verify Your Not Robot",
          "error"
        );
      }
    } catch (err) {
      swal(err);
    }
  };
  useEffect(() => {
    getStation();
  }, []);

  return (
    <div className="bg-[#ffffff] mx-1 sm:mx-32 h-fit  my-3 p-2 shadow-2xl drop-shadow-xl  rounded-md">
      <div className="flex items-center border-b-2">
        <div className="flex items-center gap-1">
          <span className="text-[#ff0000] text-base ">
            <ImStack />
          </span>
          <span className="text-[#ff0000]  text-xl py-3 font-bold">
            REGISTER GRIEVANCE / शिकायत दर्ज करें
          </span>
        </div>
      </div>
      <div className="alertBar flex items-center gap-1 my-2 px-4 py-2 bg-[#fcfc77] rounded-md  md:my-4">
        <span className="text-3xl mr-2">
          <BsInfoCircleFill />
        </span>
        <span className="font-semibold text-sm flex flex-col">
          Submit grievance by filling out all the fields below. Please fill the
          form correctly as the details entered will be used for further
          processing of your grievance.
          <span className="font-semibold text-sm">
         
          नीचे दिए गए सभी क्षेत्रों को भरकर शिकायत दर्ज करें। कृपया फ़ॉर्म को सही ढंग से भरें क्योंकि दर्ज किए गए विवरण का उपयोग आपकी शिकायत की आगे की प्रक्रिया के लिए किया जाएगा।
        </span>

        </span>
        
      </div>
      <div>
        <form
          method="post"
          className=" flex flex-col justify-center mx-6 sm:mx-28 my-10"
        >
          <div className=" my-4 flex flex-col gap-6  sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Name<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">नाम </div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="Name"
              variant="standard"
              className="w-full md:w-8/12"
              value={name}
              disabled={grievanceUploadLoading}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className=" my-4 flex flex-col gap-6  sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Father's / Husband's Name<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">पिता / पति का नाम </div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="Father's / Husband's Name"
              variant="standard"
              className="w-full md:w-8/12"
              value={fatherName}
              disabled={grievanceUploadLoading}
              onChange={(e) => setFatherName(e.target.value)}
            />
          </div>
          <div className="my-4 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Mobile Number<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">मोबाइल नंबर </div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="Phone Number"
              type="tel"
              variant="standard"
              className="w-full md:w-8/12"
              value={mobileNumber}
              disabled={grievanceUploadLoading}
              onChange={(e) => setMobilenumber(e.target.value)}
            />
          </div>
          <div className="my-4 flex flex-col sm:flex-row gap-6 items-baseline justify-start ">
            <label className="font-semibold w-full md:w-4/12 text-[#000000] ">
              <div className="eng-label">Date of Birth </div>
              <div className="hindi-label">जन्मतिथि </div>
            </label>
            <input
              type="date"
              className="w-full md:w-8/12"
              value={dob}
              disabled={grievanceUploadLoading}
              onChange={(e) => setDob(e.target.value)}
            ></input>
          </div>
          <div className="my-3 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Village-Locality Name<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">गांव-मोहल्ला का नाम</div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="Village-Locality Name / गांव-मोहल्ला का नाम"
              type="text"
              variant="standard"
              className="w-full md:w-8/12"
              value={village}
              disabled={grievanceUploadLoading}
              onChange={(e) => setVillage(e.target.value)}
            />
          </div>
          <div className="my-3 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label"> Address Line 1<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">पता लाइन 1</div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="H.No./Street/Landmark"
              type="text"
              variant="standard"
              className="w-full md:w-8/12"
              value={address1}
              disabled={grievanceUploadLoading}
              onChange={(e) => setAddress1(e.target.value)}
            />
          </div>
          <div className="my-3 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label"> Address Line 2<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">पता लाइन 2</div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="Sector/City/District"
              type="text"
              variant="standard"
              className="w-full md:w-8/12"
              value={address2}
              disabled={grievanceUploadLoading}
              onChange={(e) => setAddress2(e.target.value)}
            />
          </div>
          <div className="my-3 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Pincode<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">पिन कोड</div>
            </label>
            <TextField
              required
              id="standard-required"
              placeholder="Pincode"
              type="tel"
              variant="standard"
              className="w-full md:w-8/12"
              value={pincode}
              disabled={grievanceUploadLoading}
              onChange={(e) => setPincode(e.target.value)}
            />
          </div>
          <div className="my-4 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label"> Grievance Title<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">शिकायत का कारण</div>
            </label>
            <select
              name="gTitle"
              value={gTitle}
              onChange={(e) => setGtitle(e.target.value)}
              className="w-full md:w-8/12 p-1 outline-none border-b-2 border-[#CACAC2]"
            >
              <option value="Theft">Theft / चोरी </option>
              <option value="Violence">Violence / हिंसा </option>
              <option value="Cyber Crime">Cyber Crime / साइबर क्राइम</option>
              <option value="Drug">Drug / नशीली पदार्थ</option>
              <option value="Homicide">Homicide / मानव हत्या</option>
              <option value="Other">Other / अन्य </option>
            </select>
          </div>
          <div className="my-4 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Grievance Description<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">शिकायत का विवरण</div>
            </label>

            <TextareaAutosize
              aria-label="empty textarea"
              placeholder="Type your grievance here"
              className="w-full md:w-8/12 outline-1 outline-[#CACAC2] border-b-2 border-[#CACAC2] hover:border-black"
              minRows={5}
              value={gDescription}
              disabled={grievanceUploadLoading}
              onChange={(e) => setgDescription(e.target.value)}
            />
          </div>
          <div className="my-4 flex flex-col  gap-6 sm:flex-row items-baseline justify-start ">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Police Station Name<span className="text-red-600 ml-1 font-bold">*</span></div>
              <div className="hindi-label">पुलिस स्टेशन का नाम</div>
            </label>
            <FormControl variant="standard" className="w-full md:w-8/12">
              <Select
                id="demo-simple-select"
                placeholder="Select Station"
                className="w-full p-1 outline-none border-b-2 border-[#CACAC2]"
                value={stationid}
                label="Select Station"
                disabled={grievanceUploadLoading}
                onChange={(e) => setstationid(e.target.value)}
              >
                {stationDetails
                  ? stationDetails.map((e) => {
                      return (
                        <MenuItem key={e.stationID} value={e.stationID}>
                          {e.stationName}
                        </MenuItem>
                      );
                    })
                  : "Loading......"}
              </Select>
            </FormControl>
          </div>
          <div className="my-4 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Submit your application photo</div>
              <div className="hindi-label">
                कृपया अपने प्रार्थना पत्र की फोटो अपलोड करें
              </div>
            </label>
            <input
              className="w-full md:w-8/12"
              type="file"
              accept="image/*"
              disabled={grievanceUploadLoading}
              onChange={(e) => setGimage(e.target.files[0])}
            />
          </div>
          <div className="my-4 flex flex-col  gap-6 sm:flex-row items-baseline">
            <label className="font-semibold w-full md:w-4/12 text-[#000000]">
              <div className="eng-label">Submit your application PDF</div>
              <div className="hindi-label">
                कृपया अपने प्रार्थना पत्र की पीडीएफ अपलोड करें
              </div>
            </label>
            <input
              className="w-full md:w-8/12"
              type="file"
              accept="application/pdf"
              disabled={grievanceUploadLoading}
              onChange={(e) => setGPdf(e.target.files[0])}
            />
          </div>
          <div className="mt-6 flex justify-center">
            <ReCAPTCHA
              sitekey="6Le0fJYhAAAAAPO1XZ7AkUzWvPTdc5CbegVKorPB"
              onChange={(e) => setCaptchaValue(e)}
            />
          </div>
          <div className="w-full flex justify-center mt-3">
            <ThemeProvider theme={theme}>
              <LoadingButton
                size="small"
                color="primary"
                onClick={uploadGrievance}
                loading={grievanceUploadLoading}
                loadingIndicator={<FaHourglassEnd size={20} />}
                variant="contained"
                sx={{
                  display: "flex",
                  alignItem: "center",
                  padding: "0.5rem 1.25rem",
                  fontWeight: "bold",
                }}
              >
                Submit
              </LoadingButton>
            </ThemeProvider>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterGrievance;
